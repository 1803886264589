(() => {
  const processGForms = () => {
    if (!(timeout > 5000 || typeof jQuery !== 'undefined')) {
      timeout += 50;
      setTimeout(processGForms, 50);
      return;
    }

    try {
      if (typeof jQuery === 'function') {
        jQuery(document).on('gform_page_loaded', function (event, formId, currentPage) {
          const emailInput = document.querySelector(`#gform_${formId} input[type="email"]`);
          if (!window.HockeyStack) return;

          window.HockeyStack.goal('Move to New Page', {
            formId,
            page: currentPage,
            integration: 'Gravity Forms'
          });

          if (emailInput) {
            const email = emailInput.value.trim();
            if (email) window.HockeyStack.identify(email);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  let timeout = 0;
  processGForms();
})();
